import { Component, input, Input, OnChanges, OnInit, SimpleChanges, inject, output } from '@angular/core';
import { MultilingualString } from '@ic2/ic2-lib';
import { ModalityQuizQuestionDTO, ModalityQuizQuestionResultDTO, QuestionFlatTag, QuestionQCMAnswer, QuestionQCMAnswerDTO, Tag, TagManager } from 'common';
import { Anwser } from '../radio-answer/radio-answer.component';

@Component({
  selector: 'hiji-question-qcm',
  templateUrl: './question-qcm.component.html',
  styleUrls: ['./question-qcm.component.scss'],
  standalone: false,
})
export class QuestionQcmComponent implements OnInit, OnChanges {
  tagManager = inject(TagManager);

  readonly color = input<String>('--hiji-auto-observation');

  @Input() question: ModalityQuizQuestionDTO | ModalityQuizQuestionResultDTO;
  readonly hideSkill = input<boolean>(false);
  readonly disabled = input<boolean>(false);
  @Input()
  questionNumber: number = null;
  readonly totalQuestions = input<number>(null);
  readonly shake = input<boolean>(false);
  readonly answers = input<number[]>([]);
  readonly showCorrectAnswerAndExplanation = input<boolean>(false);
  readonly showMickeyEars = input<boolean>(false);
  @Input()
  explanation: MultilingualString = null;
  readonly autoSaved = input<boolean>(false);
  @Input()
  loadingAutoSave: boolean = false;
  readonly observe = output<any>();

  computedAnswers: {
    answer: QuestionQCMAnswerDTO | QuestionQCMAnswer;
    hasPositivePoints: boolean;
    answerForRadioAnswer: Anwser[];
  }[];
  assignedTags: QuestionFlatTag[] = [];

  showTagDesc: Tag = null;

  test: boolean = false;

  ngOnInit(): void {
    this.compute();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.compute();
  }

  compute() {
    this.computedAnswers = this.question.answers.map((answer) => {
      return {
        answer,
        hasPositivePoints: this.hasPositivePoints(answer),
        answerForRadioAnswer: [{ color: `var(${this.color()})`, nb: this.answers().filter((a) => a === answer.numAnswer).length, name: '' }],
      };
    });
    this.assignedTags = this.getAssignedTags();
  }

  getAssignedTags() {
    return this.question.tags.filter((tag) => tag.assigned);
  }

  toggleDesc(tag: Tag) {
    if (this.showTagDesc === tag) this.showTagDesc = null;
    else this.showTagDesc = tag;
  }

  hasPositivePoints(answer: QuestionQCMAnswerDTO | QuestionQCMAnswer) {
    if (answer instanceof QuestionQCMAnswer) return answer.points > 0;
    return false;
  }

  click(answer: QuestionQCMAnswerDTO | QuestionQCMAnswer) {
    this.observe.emit(answer);
  }
}
