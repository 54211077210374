import { Component, input, Input, OnInit, inject, output } from '@angular/core';
import { MultilingualString } from '@ic2/ic2-lib';
import { Criteria, CriteriaType, HighlightType, NPS_VALUES, ObservableGestureFlatTag, Tag, TagManager } from 'common';

export interface ObservationEvent {
  value?: number;
  text?: string;
  observed: boolean;
}

@Component({
    selector: 'hiji-observable-gesture-evaluation',
    templateUrl: './observable-gesture-evaluation.component.html',
    styleUrls: ['./observable-gesture-evaluation.component.scss'],
    standalone: false
})
export class ObservableGestureEvaluationComponent implements OnInit {
  tagManager = inject(TagManager);

  color = input<String>('--hiji-auto-observation"');

  @Input()
  criteria: Criteria;
  @Input()
  observableGesture: Partial<{
    idObservableGesture: number | string; //ou string pour ema-skill-observation les OG customisés
    observableGestureName: MultilingualString;
    observableGestureDescription: MultilingualString;
    highlight?: HighlightType;
    tags: ObservableGestureFlatTag[] | null;
  }>;
  readonly value = input.required<number | null>();
  @Input()
  text: string | null;

  readonly borderDanger = input<boolean>(false);

  readonly observed = input.required<boolean>();

  readonly first = input<boolean>(undefined);
  readonly hideSkill = input<boolean>(false);
  readonly disabled = input<boolean>(false);
  @Input()
  questionNumber: number = null;
  readonly totalQuestions = input<number>(null);
  readonly showDescOGInfoButton = input<boolean>(false);
  readonly withBorder = input<boolean>(true);
  readonly name = input<string>(null);
  @Input()
  loadingAutoSave: boolean = false;
  readonly autoSaved = input<boolean>(false);

  //Ces event emitter sont utilisés uniquement pour le composant de la page EMA Observe pour le moment
  readonly observe = output<ObservationEvent>();
  readonly observeText = output<ObservationEvent>();

  showOGDesc: boolean = false;
  CriteriaType: typeof CriteriaType = CriteriaType;
  showSkillDesc: boolean = false;
  showCriteriaDesc: boolean = false;
  HighlightType: typeof HighlightType = HighlightType;
  showTagDesc: Tag = null;

  npsVals = NPS_VALUES;

  ngOnInit(): void {}

  toggleDesc(tag: Tag) {
    if (this.showTagDesc === tag) this.showTagDesc = null;
    else this.showTagDesc = tag;
  }

  observable(evt: MouseEvent, value) {
    if (this.disabled()) {
      evt.preventDefault();
      return;
    }
    if (this.observed() && this.value() === value) {
      this.observe.emit({ observed: false, value: 0 });
    } else {
      this.observe.emit({ observed: true, value: value });
    }
  }

  textChange() {
    let observed = false;
    if (this.text !== null && this.text !== undefined && this.text.trim().length > 0) observed = true;
    this.observeText.emit({ observed: observed, text: this.text });
  }

  getAssignedTags() {
    return this.observableGesture.tags.filter((t) => t.assigned).map((t) => t.idTag);
  }
}
