<div class="container-fluid my-fs-2 my-fs-sm-3 position-relative pb-4">
  @if (loadingAutoSave || autoSaved()) {
    <div class="position-absolute bottom-0 end-0">
      @if (loadingAutoSave) {
        <i class="fa-light fa-spinner spinning"></i>
      } @else {
        <i class="fa-light fa-floppy-disk text-success" ngbTooltip="{{ 'question.qcm.Votre réponse a été enregistrée automatiquement' | translate }}"></i>
      }
    </div>
  }

  @if (!hideSkill()) {
    @for (qht of question.tags; track qht) {
      @if (!tagManager.getTag(qht.idTag).hideInFO) {
        <div class="row justify-content-center">
          <div class="col-auto d-flex align-items-baseline my-fs-2">
            <i class="fa-light fa-tools me-2"></i>
            <hiji-tag-display class="" [idTag]="qht.idTag" [frontMode]="true"></hiji-tag-display>
          </div>
        </div>
      }
    }
  }
  @if (showTagDesc !== null) {
    <div class="row mt-1">
      <div class="col">
        <div class="alert alert-primary" style="white-space: pre-line">{{ showTagDesc.description | ms }}</div>
      </div>
    </div>
  }
  <div class="row mt-2">
    @if (questionNumber !== null) {
      <div class="col text-muted">
        <div class="row justify-content-around">
          <div class="col">{{ 'question.qcm.Question \{\{nb\}\}/\{\{total\}\}' | translate: { nb: questionNumber, total: totalQuestions() } }}</div>
        </div>
      </div>
    }
  </div>
  <div class="row mt-2">
    <div class="col fw-bold" style="white-space: pre-line">{{ question.question | ms }}</div>
  </div>
  <div class="row mt-1 px-3">
    <div class="col text-muted my-fs-2" style="white-space: pre-line">{{ question.description | ms }}</div>
  </div>

  @if (!question.image.isEmpty()) {
    <div class="row mt-1">
      <div class="col">
        <img class="pe-none align-self-center" style="max-width: 80%; max-height: 150px" [img-preloader]="question.image | ms" />
      </div>
    </div>
  }

  @if (textAnswers() === undefined) {
    <div class="row g-0 mt-2">
      <div class="col">
        <textarea
          class="form-control"
          name="qo-{{ question.idQuestion }}"
          rows="3"
          [(ngModel)]="answer"
          (ngModelChange)="observe.emit(answer)"
          [disabled]="disabled()"
        ></textarea>
      </div>
    </div>
  }

  @for (textAnswer of textAnswers(); track trackByIndex(index, textAnswer); let index = $index) {
    <div class="row mt-2">
      @if (textAnswer.name !== null) {
        <div class="col" [ngStyle]="{ color: textAnswer.color }">
          {{ textAnswer.name }}
          @if (textAnswer.date !== undefined && showAnswerDates()) {
            <span>({{ textAnswer.date | localizedDate }})</span>
          }
        </div>
      }
    </div>
    <div class="row g-0">
      <div class="col">
        <textarea class="form-control" name="qo-result-{{ question.idQuestion }}-{{ index }}" rows="3" disabled [ngModel]="textAnswer.text"></textarea>
      </div>
    </div>
  }
</div>
