<div class="container-fluid p-0 my-fs-2 my-fs-sm-3 position-relative">
  @if (!hideSkill()) {
    @for (qht of assignedTags; track qht) {
      @if (!tagManager.getTag(qht.idTag).hideInFO) {
        <div class="row justify-content-center">
          <div class="col-auto d-flex align-items-baseline my-fs-2">
            <i class="fa-light fa-tools me-2"></i>
            <hiji-tag-display class="" [idTag]="qht.idTag" [frontMode]="true" (descInfoClick)="toggleDesc($event)"></hiji-tag-display>
          </div>
          <!-- @if (autoSaveEnabled) {
          <div class="col-auto">
            @if (autoSaved) {
              <i class="fa-light fa-floppy-disk"></i>
            } @else {
              <i class="fa-light fa-floppy-disk-xmark"></i>
            }
          </div>
          } -->
        </div>
      }
    }
  }

  @if (loadingAutoSave || autoSaved()) {
    <div class="position-absolute bottom-0 end-0 pe-1 pb-1">
      @if (loadingAutoSave) {
        <i class="fa-light fa-spinner spinning"></i>
      } @else {
        <i class="fa-light fa-floppy-disk text-success" ngbTooltip="{{ 'question.qcm.Votre réponse a été enregistrée automatiquement' | translate }}"></i>
      }
    </div>
  }

  @if (showTagDesc !== null) {
    <div class="row mt-1">
      <div class="col">
        <div class="alert alert-primary" style="white-space: pre-line">{{ showTagDesc.description | ms }}</div>
      </div>
    </div>
  }
  <div class="row mt-2 px-3">
    @if (questionNumber !== null) {
      <div class="col text-muted">
        <div class="row justify-content-around">
          <div class="col">{{ 'question.qcm.Question \{\{nb\}\}/\{\{total\}\}' | translate: { nb: questionNumber, total: totalQuestions() } }}</div>
        </div>
      </div>
    }
  </div>
  <div class="row mt-2 px-3">
    <div class="col fw-bold" style="white-space: pre-line">
      {{ question.question | ms }}
    </div>
  </div>
  <div class="row mt-1 px-3">
    <div class="col text-muted my-fs-2" style="white-space: pre-line">{{ question.description | ms }}</div>
  </div>
  <div class="row mt-1 px-3">
    <div class="col text-center">
      @if (!question.image.isEmpty()) {
        <img class="pe-none align-self-center" style="max-width: 80%" [img-preloader]="question.image | ms" />
      }
    </div>
  </div>
  @if (question.showNumberOfAnswersHint) {
    <div class="row mt-2 px-3">
      <div class="col text-muted my-fs-2 animate__animated" [class.animate__shakeX]="shake()">
        {{
        question.nbOfAnswers === 1
        ? ('question.qcm.1 réponse attendue' | translate: { nb: question.nbOfAnswers })
        : ('question.qcm.\{\{nb\}\} réponses attendues' | translate: { nb: question.nbOfAnswers })
        }}
      </div>
    </div>
  }
  <div class="row g-0 mx-4 pb-3">
    @for (computedAnswer of computedAnswers | orderBy: 'answer.order'; track $index) {
      <div class="col-12 mt-3" [class.pointer]="!disabled()" (click)="click(computedAnswer.answer)">
        <div class="border border-light30p rounded-3 p-2" [class.border-success]="showCorrectAnswerAndExplanation() && computedAnswer.hasPositivePoints">
          <div class="row g-0 flex-grow-1">
            <div class="col-auto d-flex">
              <hiji-radio-answer class="radio-size" [showMickeyEars]="showMickeyEars()" [answers]="computedAnswer.answerForRadioAnswer"></hiji-radio-answer>
            </div>
            <div class="col user-select-none d-flex flex-column px-3 justify-content-center">
              <div class="fw-bold" [class.align-self-center]="!computedAnswer.answer.answerImage.isEmpty()" style="white-space: pre-wrap">
                {{ computedAnswer.answer.answer | ms }}
              </div>
              @if (!computedAnswer.answer.answerImage.isEmpty()) {
                <img
                  class="pe-none align-self-center mt-2"
                  style="max-width: 80%"
                  [img-preloader]="computedAnswer.answer.answerImage | ms"
                  />
              }
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  @if (showCorrectAnswerAndExplanation() && !explanation.isEmpty()) {
    <div class="row">
      <div class="col">
        <div class="alert alert-warning">
          {{ explanation | ms }}
        </div>
      </div>
    </div>
  }
</div>
