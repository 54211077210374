import { Component, Input, OnChanges, SimpleChanges, inject, output, input } from '@angular/core';
import { MultilingualString } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { Criteria, CriteriaType, HighlightType, NPS_VALUES, ObservableGestureFlatTag, Tag, TagManager } from 'common';
import { AnswerModel, CriteriaAnswerModel, TextAnswerModel } from './AnswerModel';

interface ComputedAnswerModel {
  value: number;
  answers: {
    name: string;
    color: string;
    nb: number;
    users?: string[];
    start: number;
    part: number;
  }[];
  dates: { name: string; color: string; date: Date; user?: string }[];
}

@Component({
    selector: 'hiji-observable-gesture-result',
    templateUrl: './observable-gesture-result.component.html',
    styleUrls: ['./observable-gesture-result.component.scss'],
    standalone: false
})
export class ObservableGestureResultComponent implements OnChanges {
  tagManager = inject(TagManager);
  private translate = inject(TranslateService);

  @Input()
  criteria: Criteria;
  @Input()
  observableGesture: {
    idObservableGesture: number | string; //ou string pour ema-skill-observation les OG customisés
    tags: ObservableGestureFlatTag[] | null;
    observableGestureName: MultilingualString | null;
    observableGestureDescription: MultilingualString | null;
    highlight?: HighlightType | null;
    toString(): string;
  } = null;

  readonly calculateDonutByNumberOfAnswerTypes = input<boolean>(false);
  readonly showSkill = input<boolean>(true);
  readonly answers = input<AnswerModel[]>(undefined);
  readonly textAnswers = input<TextAnswerModel[]>(undefined);
  readonly ogBold = input<boolean>(false);
  readonly criteriaTextClasses = input<string>(undefined);
  readonly showMickeyEars = input<boolean>(true);
  readonly showAnswerDates = input<boolean>(false);
  readonly evaluationMode = input<boolean>(false);
  readonly acquisitionMode = input<boolean>(false);

  readonly observe = output<number>();
  readonly observeText = output<string>();

  text: string = '';
  showTagDesc: Tag = null;
  npsVals = NPS_VALUES;
  computedAnswers = new Map<number, ComputedAnswerModel>();

  CriteriaType: typeof CriteriaType = CriteriaType;
  HighlightType: typeof HighlightType = HighlightType;

  toggleDesc(tag: Tag) {
    if (this.showTagDesc === tag) this.showTagDesc = null;
    else this.showTagDesc = tag;
  }

  emit(criteriaValue: number) {
    if (!this.evaluationMode()) return;
    this.observe.emit(criteriaValue);
    this.computeAnswers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.answers) {
      this.computeAnswers();
    }
  }

  getAnswers(criteriaValue: number) {
    const answers = this.computedAnswers.get(criteriaValue);
    if (answers === undefined) return []; //Should not be possible if answers are computed for each criteria value but just in case to avoid a crash
    return answers.answers;
  }

  getAnswersUsers(criteriaValue: number) {
    const answers = this.computedAnswers.get(criteriaValue);
    if (answers === undefined) return []; //Should not be possible if answers are computed for each criteria value but just in case to avoid a crash
    const users = answers.answers.reduce((acc, answer) => {
      if (answer.users) {
        acc.push(...answer.users);
      }
      return acc;
    }, []);
    return users;
  }

  getAnswersUsersAcquistionBefore(criteriaValue: number) {
    const answers = this.computedAnswers.get(criteriaValue);
    if (answers === undefined) return []; //Should not be possible if answers are computed for each criteria value but just in case to avoid a crash
    return answers.answers.filter((a) => a.color === 'var(--hiji-acquisition-before)')[0].users;
  }

  getAnswersUsersAcquistionAfter(criteriaValue: number) {
    const answers = this.computedAnswers.get(criteriaValue);
    if (answers === undefined) return []; //Should not be possible if answers are computed for each criteria value but just in case to avoid a crash
    return answers.answers.filter((a) => a.color === 'var(--hiji-acquisition-after)')[0].users;
  }

  getAnswerDates(criteriaValue: number) {
    const answers = this.computedAnswers.get(criteriaValue);
    if (answers === undefined) return []; //Should not be possible if answers are computed for each criteria value but just in case to avoid a crash
    return answers.dates;
  }

  computeAnswers() {
    this.computedAnswers.clear();
    this.answers()
      .map((a) => {
        return {
          value: a.value,
          answers: a.answers,
          dates: this.computeAnswerDates(a.answers),
        } as ComputedAnswerModel;
      })
      .forEach((a: ComputedAnswerModel) => {
        this.computedAnswers.set(a.value, a);
      });
  }

  computeAnswerDates(answers: CriteriaAnswerModel[]) {
    return answers
      .flatMap((a) => {
        if (a.dates)
          return a.dates.map((d) => {
            return { name: a.name, color: a.color, date: d };
          });
        if (a.datesWithUser)
          return a.datesWithUser.map((d) => {
            return { name: a.name, color: a.color, date: d.date, user: d.user };
          });
        return [];
      })
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  hasAnswers(criteriaValue: number) {
    const answersForThisValue = this.computedAnswers.get(criteriaValue);
    if (answersForThisValue === undefined) return false;
    return answersForThisValue.answers.map((a) => a.nb).reduce((a, b) => a + b, 0) > 0;
  }

  getAssignedTags() {
    return this.observableGesture.tags.filter((t) => t.assigned);
  }

  trackByIndex(index: number, el: any): number {
    return index;
  }
}
