import { Component, Input, inject, output, input } from '@angular/core';
import { ModalityQuizQuestionDTO, Tag, TagManager } from 'common';
import { TextAnswerModel } from '../observable-gesture-result/AnswerModel';

@Component({
    selector: 'hiji-question-ouverte',
    templateUrl: './question-ouverte.component.html',
    styleUrls: ['./question-ouverte.component.scss'],
    standalone: false
})
export class QuestionOuverteComponent {
  tagManager = inject(TagManager);

  @Input()
  question: ModalityQuizQuestionDTO;
  readonly hideSkill = input<boolean>(false);
  readonly disabled = input<boolean>(false);
  @Input()
  questionNumber: number = null;
  readonly totalQuestions = input<number>(null);
  readonly observe = output<any>();
  @Input()
  answer: string = null;
  readonly showAnswerDates = input<boolean>(false);
  readonly textAnswers = input<TextAnswerModel[]>(undefined);
  @Input()
  loadingAutoSave: boolean = false;
  readonly autoSaved = input<boolean>(false);

  showTagDesc: Tag = null;

  trackByIndex(index: number, el: any): number {
    return index;
  }
}
